<template>
    <v-container>

        <!-- HEADER -->
        <v-row class="mt-5" align="center">
            <v-col cols="12">
                <div class="title-section">
                    Cumpleaños
                </div>
            </v-col>
            <v-col cols="4">
                <v-icon color="black" large>mdi-cake-variant-outline</v-icon>
                <b>Listado de cumpleaños</b>
            </v-col>
            <v-col cols="4">
                <v-btn v-if="isAdmin || isAmbUser" class="normal-btn" color="primary" depressed
                       @click="showCreateDialog">
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    Crear cumpleaños
                </v-btn>
            </v-col>
            <v-col cols="4">
                <kimsa-text-field
                    placeholder="Buscar"
                    append-icon="mdi-magnify"
                    outlined dense solo flat
                    @change="search = $event"
                />
            </v-col>
        </v-row>

        <!-- TABLE -->
        <v-data-table
            class="mt-5"
            :headers="headers"
            :loading="loading"
            :items="birthdays"
            :items-per-page="5"
        >
            <template v-slot:item.actions="{ item }">
                <v-btn class="mr-1 my-1" fab outlined x-small @click.stop="editItem(item)">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="isAdmin" class="mr-1 my-1" fab outlined x-small @click.stop="deleteItem(item)">
                    <v-icon color="primary">mdi-delete</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <!-- COMPONENTS -->
        <create-birthday-dialog @success="loadBirthdays" ref="creator"/>

        <kimsa-confirm
            title="Eliminar"
            content="¿Esta seguro que desea eliminar este Cumpleaños?"
            persistent
            @confirm="onConfirmDelete"
            @cancel="currentId = null"
            :loading="loadingDelete"
            no-close-on-confirm
            ref="confirm"
        />

    </v-container>
</template>

<script>
import Axios from "@/utils/Axios";
import KimsaConfirm from "@/components/common/KimsaConfirm";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import CreateBirthdayDialog from "@/components/bithday/CreateBirthdayDialog";
import {debounce} from "@/utils/Common";

export default {
    name: "Birthdays",
    components: {CreateBirthdayDialog, KimsaTextField, KimsaConfirm},
    data() {
        return {
            loading: false,
            birthdays: [],
            search: '',
            currentId: null,
            loadingDelete: false,
            headers: [
                {text: 'Nombre', value: 'name', class: 'grey white--text'},
                {text: 'Apellido', value: 'last_name', class: 'grey white--text'},
                {text: 'Email', value: 'email', class: 'grey white--text'},
                {text: 'Fecha de Nacimiento', value: 'birth_date', class: 'grey white--text'},
                {text: '', value: 'actions', sortable: false, class: 'grey'},
            ],
        }
    },
    async mounted() {
        const vm = this
        await vm.loadBirthdays()
    },
    methods: {
        async loadBirthdays() {
            const vm = this
            vm.loading = true

            await Axios.post('birthdays', {search: vm.search}).then(res => {
                console.log('res loadBirthdays', res)
                vm.birthdays = res.data.result.birthdays.map(birthday => ({
                    ...birthday,
                    birth_date: vm.$moment(birthday.birth_date).format('DD-MM-YYYY')
                }))
                console.log('vm.birthdays', vm.birthdays)
            }).catch(er => {
                console.log('error loadBirthdays', er)
            }).finally(() => {
                vm.loading = false
            })
        },
        debouncedLoadBirthdays: debounce(
            function () {
                this.loadBirthdays()
            }, 500),
        showCreateDialog() {
            const vm = this
            vm.$refs.creator.show()
        },
        editItem(item) {
            const vm = this
            vm.$refs.creator.loadBirthday(item.id)
        },
        deleteItem(item) {
            const vm = this
            vm.$refs.confirm.show()
            vm.currentId = item.id
        },
        async onConfirmDelete() {
            const vm = this
            vm.loadingDelete = true

            await Axios.delete(`birthdays/${vm.currentId}/delete`).then(res => {
                // console.log('res onConfirmDelete',res)
                vm.toast('Cumpleaños eliminado')
            }).catch(er => {
                console.log('er', er)
                vm.toast('error onConfirmDelete', er)
            })

            vm.loadingDelete = false
            vm.currentId = null

            vm.$refs.confirm.hide()
            await vm.loadBirthdays()
        },
    },
    watch: {
        search() {
            this.debouncedLoadBirthdays()
        }
    }
}
</script>

<style scoped>

.title-section {
    color: var(--v-primary-base);
}

</style>