<template>
    <v-dialog v-model="dialog" max-width="700px" @click:outside="hide" scrollable>
        <v-card tile>
            <v-card-title>
                <v-icon color="black" left size="40">
                    {{ editMode ? 'mdi-account-outline' : 'mdi-account-plus-outline' }}
                </v-icon>
                <div class="title-dialog">
                    {{ editMode ? 'Editar' : 'Agregar' }} Cumpleaños
                    <div class="title-subtext">
                        Completa los siguientes datos
                    </div>
                </div>
                <v-spacer/>
                <v-btn icon color="accent" large @click="hide">
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <kimsa-text-field
                            label="Nombre"
                            placeholder="Ingresar Nombre"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :value="name"
                            @change="name = $event"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-text-field
                            label="Apellido"
                            placeholder="Ingresar Apellido"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :value="lastName"
                            @change="lastName = $event"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-text-field
                            label="Email"
                            placeholder="Ingresar Email"
                            bolder-label
                            outlined dense
                            force-value
                            inputMode="email"
                            :value="email"
                            @change="email = $event"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-date-picker
                            label="Fecha de Nacimiento"
                            placeholder="Ingresar Fecha de Nacimiento"
                            bolder-label
                            outlined dense
                            force-value
                            birth-date-mode
                            :value="birthDate"
                            @change="birthDate = $event"
                            min="1900-01-01"
                            max="2020-12-31"
                        />
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn @click="editMode ? editBirthday() : saveBirthday()" tile color="primary" depressed
                               :disabled="!valid || loadingDelete" :loading="loading">
                            {{ editMode ? 'GUARDAR' : 'AGREGAR' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import Axios from "@/utils/Axios";
import KimsaDatePicker from "@/components/common/inputs/KimsaDatePicker";

export default {
    name: "CreateBirthdayDialog",
    components: {KimsaDatePicker, KimsaTextField},
    data() {
        return {
            dialog: false,
            loading: false,
            loadingDelete: false,
            editMode: false,
            birthday: null,
            name: '',
            lastName: '',
            email: '',
            birthDate: '',
        }
    },
    computed: {
        ...mapState(['dialogActive']),
        valid(vm = this) {
            return !!vm.name && !!vm.lastName && vm.validEmail(vm.email) && !!vm.birthDate
        }
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        show() {
            const vm = this
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            setTimeout(() => {
                vm.reset()
            }, 500)
        },
        reset() {
            const vm = this
            vm.editMode = false
            vm.name = ''
            vm.lastName = ''
            vm.email = ''
            vm.birthDate = ''
        },
        async loadBirthday(id) {
            const vm = this
            await Axios.get(`birthdays/${id}`).then(res => {
                // console.log('res loadBirthday', res)
                vm.birthday = res.data.result.birthday
                vm.editMode = true
                vm.name = vm.birthday.name
                vm.lastName = vm.birthday.last_name
                vm.email = vm.birthday.email
                vm.birthDate = vm.$moment(vm.birthday.birth_date).format('YYYY-MM-DD')
                vm.show()
            }).catch(er => {
                console.log('error loadBirthday', er)
            })
        },
        async saveBirthday() {
            const vm = this
            vm.loading = true
            // console.log('saveBirthday', vm)

            let payload = {
                name: vm.name,
                last_name: vm.lastName,
                email: vm.email,
                birth_date: vm.birthDate,
            }

            // console.log('payload', payload)

            await Axios.post('birthdays/new', payload).then(res => {
                // console.log('res saveBirthday',res)
                vm.toast('Cumpleaños creado')
                vm.$emit('success')
            }).catch(error => {
                console.log('error saveBirthday', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
        async editBirthday() {
            const vm = this
            vm.loading = true

            // console.log('editBirthday', vm)

            let payload = {
                name: vm.name,
                last_name: vm.lastName,
                email: vm.email,
                birth_date: vm.birthDate,
            }

            // console.log('payload', payload)

            await Axios.patch(`birthdays/${vm.birthday.id}/edit`, payload).then(res => {
                // console.log('res editBirthday',res)
                vm.toast('Cumpleaños editado')
                vm.$emit('success')
            }).catch(error => {
                console.log('error editBirthday', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
        validEmail(email) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(email) || false
        }
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        },
    }
}
</script>

<style scoped>

.title-dialog {
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.title-subtext {
    font-size: 11px;
    color: #a8a8a8;
    line-height: .5;
    text-transform: none;
    font-weight: normal;
}

.label {
    font-size: 15px;
    color: #333333;
}

</style>