export const randomString = (length = 6, suffix = '', prefix = '') => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    if (prefix) prefix = `${prefix}_`
    if (suffix) suffix = `_${suffix}`

    return `${prefix}${result}${suffix}`;
}

export const debounce = (func, wait, immediate = false) => {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export const toRomanNumeral = (n) => {
    var
        values = [1, 5, 10, 50, 100, 500, 1000],
        letras = ['I', 'V', 'X', 'L', 'C', 'D', 'M'],
        res = [],
        num, letra, val, pos, insert

    for(var i = 6; num = values[i], letra = letras[i]; i--) {
        // Suficientemente grande
        if(n >= num) {
            // Número de letras repetidas
            var r = Math.floor(n / num);

            // Restamos el actual
            n -= r * num;

            if(r < 4){
                // Metemos las letras
                while(r--){
                    res.push(letra);
                }
            } else {
                // No se pueden repetir 4+ veces
                val = res.pop(); // Última letra

                // Si es el string vacío o letra == "M" (no hay anterior)
                // usamos la letra anterior a esta
                pos = (val ? letras.indexOf(val) : i) + 1;

                // Y si letra == "M" -> letras[pos] no existirá y usamos M
                insert = letra + (letras[pos] || 'M');

                // Insertamos el string
                res.push(insert);
            }
        } else {
            // Si no vamos a poner letra usamos un ""
            // para que no afecte pop
            res.push('');
        }
    }

    return res.join('');
}

export const rutValidator = (v) => {
    if (!v) return ''
    // eslint-disable-next-line no-useless-escape
    var value = v.replace(/\,|\-|\./g,'')

    // Aislar Cuerpo y Dígito Verificador
    let body = value.slice(0,-1);
    let dv = value.slice(-1).toUpperCase();

    value = body + '-'+ dv

    if(body.length < 7) return "RUT Incompleto"

    let sum = 0;
    let mult = 2;

    for(var i=1; i<=body.length; i++) {

        let index = mult * value.charAt(body.length - i);
        sum = sum + index;
        if(mult < 7) { mult = mult + 1; } else { mult = 2; }

    }

    let targetDV = 11 - (sum % 11);

    // Casos Especiales (0 y K)
    dv = (dv == 'K')?10:dv;
    dv = (dv == 0)?11:dv;

    if(targetDV != dv) return false

    return true

}